const controlModal = (openElement) => {
  const target = document.querySelectorAll('.' + openElement + ':not(.listened)');
  for (let i = 0; i < target.length; i++) {
    const targetContent = target[i].getElementsByClassName("js-modal-content")[0];

    // just add event listener for new element
    target[i].classList.add("listened");
    target[i].addEventListener('click', () => {
      if (targetContent.classList.contains("is-open") == false) {
        targetContent.classList.add("is-open");
        return false
      } else {
        targetContent.classList.remove('is-open');
        return false
      }
    });
    document.addEventListener('click', (e) => {
      let isClicked = target[i].contains(e.target);
      if (!isClicked) {
        targetContent.classList.remove('is-open');
        return false
      }
    });
  }
}

export { controlModal };