let controlNav = () => {
  $(".js-nav").click(() => {
    $("#nav__container").toggleClass("is-open");
  });
  window.addEventListener('resize', () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  })

}

export {controlNav}