const shareButton = () => {
  const href = encodeURIComponent(location.href);
  const title = encodeURIComponent($("title").html());

  $(".js-share-button").each(function () {
    const sns = $(this).data("sns");

    switch (sns) {
      case "line":
        $(this).attr(
          "href",
          "http://line.me/R/msg/text/?" + title + "%20" + href
        );
        break;

      case "facebook":
        $(this).attr("href", "http://www.facebook.com/sharer.php?u=" + href);
        break;

      case "twitter":
        $(this).attr(
          "href",
          "http://twitter.com/share?text=" + title + "&url=" + href
        );
        break;
    }
  });

  $(".js-copy-button").on("click", (e) => {
    e.preventDefault();
    clipURL();
  })
  function clipURL() {
    $("body").append(
      '<textarea id="currentURL" style="position:fixed;left:-100%;">' +
        location.href +
        "</textarea>"
    );
    $("#currentURL").select();
    document.execCommand("copy");
    $("#currentURL").remove();
    alert("URLをコピーしました。");
  }

  // let isOpen = false;
  const toggleShareModal = () => {
    $(".js-open-share").on("click", () => {
      $(".share__block")
        .fadeToggle(100)
        .addClass("is-open")
    });
  };
  // toggleShareModal();

  const closeShareModal = () => {
    // $(".js-share-modal__close-button").on("click", () => {
    //   $(".share__block")
    //     .fadeOut(100)
    //     .removeClass("is-active");
    // });
    $("body").on("click", (e) => {
      e.preventDefault
      if ($(".share__block").hasClass("is-open")) {
        $(".share__block").fadeOut(100).removeClass("is-open")
      } else {
        return
      }
    })
  };
  // closeShareModal();

};

export { shareButton };
