import { controlNav } from "./nav";
import slick from "slick-carousel";
import { shareButton } from "./share"
import { controlModal } from "./modal"

controlNav();
shareButton();
controlModal("js-open-modal")
// mv slider
$(".js-mv-slider").slick({
  arrows: false,
  dots: true,
  appendDots: $("#js-mv-slider-dots"),
  dotclass: "slick-dots",
});
// $(".js-mv-slider--top").slick({
//   arrows: false,
//   dots: false,
//   autoplay: true,
//   autoplaySpeed: 5000,
// });

window.addEventListener("DOMContentLoaded",() => {
  // smoothscroll
  $('a[href^="#"]').click(function () {
    var speed = 500;
    var href = $(this).attr("href");
    var target = $(href == "#" || href == "" ? 'html' : href);
    var position = target.offset().top - 20;
    $("html, body").animate({ scrollTop: position }, speed, "swing");
    return false;
  });

  // 
  let isSP = /iPhone|iPod|iPad|Android/i.test(window.navigator.userAgent)
  if(isSP) {
    document.getElementById("mv__container").style.height = `${window.outerHeight}px`
  }
})


// debug
// const debugUrl = new URL(location.href);
// if (debugUrl.searchParams.get('debug') === 'mundi') {
//   $("body").css("display", "block")
// } else {
//   $("body").css("display", "none")
// }